<template>
  <div>
    <h1>Panier</h1>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col">Quantite</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in getPanier" v-bind:key="item.product.name">
          <td>{{ item.product.name }}</td>
          <td>{{ item.quantite }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="col">Total</th>
          <th scope="col">
            {{ getTotal|currency }}
          </th>
        </tr>
      </tfoot>
    </table>
    <p></p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "panier",
  data() {
    return {
      total: 0,
    };
  },
  computed: {
    ...mapGetters("panier", ["getPanier", "getTotal"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

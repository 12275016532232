<template>
  <div class="card col-3">
    <router-link v-bind:to="'/produit/' + product.id" ><img v-bind:src="product.img" class="card-img-top" alt="..." /></router-link>
    <div class="card-body">
      <h5 class="card-title">{{ product.name }}</h5>
      <p class="card-text">
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </p>
      <add-to-panier :product="product" />
    </div>
  </div>
</template>

<script>
import AddToPanier from './AddToPanier.vue';
export default {
  name: "fiche-produit",  
  props: ["product"],  
  components: {
      AddToPanier
  }
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import panier from './panier'
import produits from './produits'

Vue.use(Vuex)

export default new Vuex.Store({    
    modules: {
        panier: panier,
        produits: produits
    }
})

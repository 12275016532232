import axios from 'axios'

export default {
    namespaced: true,
    state: {
        produits: [],
    },
    getters: {
        getProduits: state => {
            return state.produits;
        },
        getProduit:  (state) => (id) => {            
            return state.produits[id];
        },
    },
    mutations: {
        setProduits(state, products) {
            state.produits = products
        }
    },
    actions: {
        init: (context) => {
            axios.get("/data/products.json").then((res) => {
                context.commit("setProduits", res.data);
                console.log(res);
            }).catch(err => console.log(err))
            console.log('coucou');
        }
    }

}
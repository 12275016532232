<template>
  <div>
    {{ id }}

    <div class="card col-12">
      <img v-bind:src="getProduit(id).img" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">{{ getProduit(id).name }}</h5>
        <p class="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
        <add-to-panier :product="getProduit(id)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddToPanier from "./AddToPanier.vue";
export default {
  name: "detail-produit",
  props: ["id"],
  components: {
    AddToPanier,
  },
  computed: {
      ... mapGetters("produits", ["getProduit"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

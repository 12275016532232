

export default {
    namespaced: true,
    state: {
        panier: [],
    },
    getters: {
        getPanier: state => {

            return state.panier;
        },
        getTotal: state => {
            let total = 0;
            state.panier.forEach((p) => (total += p.product.prix));
            return total;
        }
    },
    mutations: {
        addItem(state, item) {
            state.panier.push(item);
            localStorage.setItem("panier", JSON.stringify(state.panier));
        },
        delPanier(state, item) {
            const index = state.panier.indexOf(item);
            if (index > -1) {
                if (index > -1) {
                    state.panier.splice(index, 1);
                }
            }
            this.commit("writeInLocalStorage");
        },
        emptyPanier(state) {
            state.panier = [];
            this.commit("writeInLocalStorage");
        },
        intializeStore(state){
            console.log("initialize")
            if(localStorage.getItem("panier")){
               state.panier = JSON.parse(localStorage.getItem("panier"));
            }
        },
        writeInLocalStorage(state) {
            localStorage.setItem("panier", state);
        }

    },

}
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './store'
import router from './router'
import VueLocalStorage from 'vue-localstorage'
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.filter("currency", (value) => {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
    });
    return formatter.format(value);
})

Vue.use(VueLocalStorage)
new Vue({
    store,
    router,
    beforeCreate() {
        console.log("beforeCreate");
        this.$store.commit("panier/intializeStore");
    },
    render: h => h(App)
}).$mount('#app')

<template>
  <div class="container">
    <div class="row">
      <fiche-produit
        :product="product"
        v-for="product in getProduits"
        v-bind:key="product.name"
      />
    </div>
  </div>
</template>

<script>
import FicheProduit from "./FicheProduit.vue";
import { mapGetters } from "vuex";

export default {
  name: "liste-produits",
  components: {
    FicheProduit,
  },
  computed: {
    ...mapGetters('produits',["getProduits"]),
  },
  
  mounted: function(){
      this.$store.dispatch("produits/init");
  }
  /*
  mounted: function () {
    this.$axios.get("/data/products.json").then((res) => {
      this.products = res.data;
      this.loading = false;
    });
  },*/
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

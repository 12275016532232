<template>
  <div>
    <input v-model="quantity" />
    <a v-on:click="addPanier" class="btn btn-primary">Add panier</a>
  </div>
</template>

<script>
export default {
  name: "add-to-panier",
  data() {
    return {
      quantity: 1,
    };
  },
  props: ["product"],
  methods: {
    addPanier() {
      let item = {
        quantite: this.quantity,
        product: this.product,
      };
      this.$store.commit('panier/addItem', item);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
